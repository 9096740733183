.aboutme-section {
	padding: 80px 20px;
	background: linear-gradient(to right, #003366, #004080);
	position: relative; /* Ważne dla pozycjonowania linii */
}

.container {
	max-width: 1200px;
	margin: 0 auto;
}

.aboutme-content {
	display: block;
	text-align: left;
}

.aboutme-text {
	padding: 0 40px;
}

.aboutme-heading {
	font-size: 2.5rem;
	text-align: center;
	font-weight: bold;
	color: white; /* Kolor nagłówka */
	text-transform: uppercase;
	margin-bottom: 20px;
}

.aboutme-text p {
	font-size: 1.2rem;
	line-height: 1.8;
	margin-bottom: 20px;
	color: white; /* Kolor tekstu */
}

.aboutme-list {
	list-style-type: disc;
	padding-left: 20px;
	margin-top: 20px;
	color: white; /* Kolor listy */
}

.aboutme-list li {
	margin-bottom: 10px;
}

@media (max-width: 768px) {
	.aboutme-content {
		text-align: center;
	}

	.aboutme-text {
		padding: 0 20px;
	}
}
.aboutme-list {
	list-style: none; /* Remove default bullet points */
	padding-left: 0;
	margin-top: 20px;
}

.aboutme-list li {
	font-size: 18px;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.aboutme-list .icon {
	margin-right: 10px;
	color: white; /* Adjust the color */
	font-size: 1.2rem; /* Adjust the size */
}
