.home-section {
	height: 100vh;
	background: url('../img/room.jpg') no-repeat center/cover; /* Adjusted background position */
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.overlay {
	background-color: rgba(0, 0, 0, 0.5); /* Slight dark overlay for better text contrast */
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.content {
	position: relative;
	color: white;
	text-align: center;
}

.heading {
	font-size: 3rem;
	font-weight: bold;
	text-transform: uppercase;
}

.subheading {
	font-size: 3rem;
	margin-top: 20px;
	text-transform: none;
	font-weight: bold;
}
.heading,
.subheading {
	text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
}
@media (max-width: 768px) {
	.heading {
		font-size: 2rem;
	}

	.subheading {
		font-size: 2rem;
	}
}
