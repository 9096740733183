.rules-section {
	padding: 80px 20px;
	background: linear-gradient(to right, #003366, #004080); /* Subtle gradient background */
	color: white;
}

.container {
	max-width: 1200px;
	margin: 0 auto;
}

.rules-heading {
	text-align: center;
	font-size: 2.8rem;
	font-weight: bold;
	color: white;
	margin-bottom: 50px;
}

.rules-grid {
	display: grid;
	grid-template-columns: 1fr 1fr; /* Two equal-width columns */
	gap: 30px;
}

.rules-column {
	display: flex;
	flex-direction: column;
	gap: 20px;
	flex: 1;
}

.rules-item {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 30px;
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 8px;
	backdrop-filter: blur(5px);
	transition: transform 0.3s ease, box-shadow 0.3s ease;
	min-height: 100px;
	height: auto;
	flex-grow: 1; /* Make each item stretch to fill the column */
}

.rules-item:hover {
	transform: translateY(-5px);
	box-shadow: 0 8px 20px rgba(255, 255, 255, 0.2);
}

.rules-item p {
	margin: 0;
	font-size: 1.2rem;
	text-align: justify;
	line-height: 1.8;
	flex-grow: 1;
}

.rules-icon-bottom {
	position: absolute;
	bottom: 10px;
	right: 10px;
	font-size: 2.5rem;
	color: rgba(102, 192, 255, 0.7);
	transition: transform 0.3s ease, color 0.3s ease;
}

.rules-item:hover .rules-icon-bottom {
	transform: rotate(10deg);
	color: rgba(102, 192, 255, 1);
}

@media (max-width: 768px) {
	.rules-grid {
		grid-template-columns: 1fr; /* One column on mobile */
	}

	.rules-heading {
		font-size: 2.2rem;
	}

	.rules-item {
		min-height: auto;
	}

	.rules-item p {
		text-align: left; /* Prevent justification issues on mobile */
	}

	.rules-icon-bottom {
		font-size: 2rem;
	}
}
