.navbar-custom {
	background-color: transparent;
	transition: background-color 0.5s ease;
	padding: 15px 20px;
	z-index: 1000;
	font-weight: bold;
	width: 100%;
}
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');

.navbar-solid {
	background-color: rgba(255, 255, 255, 0.9);
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.navbar-custom .navbar-brand {
	color: white;
}

.navbar-solid .navbar-brand {
	color: rgba(0, 51, 102, 1); /* Navy Blue for solid background */
}

/* Default state for navbar links */
.navbar-custom .nav-link {
	color: white;
	transition: color 0.3s ease;
}

.navbar-solid .nav-link {
	color: rgba(0, 51, 102, 1); /* Navy Blue links when navbar is solid */
}

/* Active link style */
.navbar-solid .nav-link.active,
.navbar-custom .nav-link.active {
	color: rgba(0, 51, 102, 1) !important; /* Navy blue for active link */
	font-weight: bold; /* Highlight the active link */
}

/* Hover state */
.navbar-solid .nav-link:hover,
.navbar-custom .nav-link:hover {
	color: rgba(0, 51, 102, 1) !important; /* Navy blue when hovered */
}

/* Customize active link when navbar is transparent */
.navbar-custom .nav-link.active {
	color: rgba(102, 192, 255, 1) !important; /* Lighter blue for active link when navbar is transparent */
}
