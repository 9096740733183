.help-section {
	padding: 80px 20px;
	background-color: #f0f8ff;
	color: #003366;
}

.container {
	max-width: 1200px;
	margin: 0 auto;
}

.help-heading {
	font-size: 2.5rem;
	text-align: center;
	margin-bottom: 40px;
	font-weight: bold;
	color: #003366;
	text-transform: uppercase;
}

.help-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	gap: 20px;
}

.help-tile {
	background-color: white;
	border-radius: 10px;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
	padding: 20px;
	font-size: 1.2rem;
	line-height: 1.6;
	transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.help-tile:hover {
	transform: translateY(-5px);
	box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.help-extra {
	text-align: center;
	font-size: 1.5rem; /* Większa czcionka dla wyróżnienia */
	margin-top: 60px; /* Większy margines dla odstępu */
	color: #003366;
	font-style: italic;
}

@media (max-width: 768px) {
	.help-heading {
		font-size: 2rem;
	}

	.help-tile {
		font-size: 1rem;
		padding: 15px;
	}

	.help-extra {
		font-size: 1.3rem;
		margin-top: 40px;
	}
}
