.contact-section {
	padding: 80px 20px;
	background-color: #f0f8ff; /* Blue gradient background */
	color: #003366;
	position: relative;
}

.container {
	max-width: 1200px;
	margin: 0 auto;
	position: relative;
	z-index: 1;
}

.contact-heading {
	text-align: center;
	font-size: 3rem;
	color: #003366;
	margin-bottom: 40px;
	font-weight: bold;
}

.contact-content {
	background: rgba(255, 255, 255, 0.1);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	gap: 40px;
}

.contact-details {
	flex: 1;
	max-width: 600px;
	font-size: 1.2rem;
	background: rgba(255, 255, 255, 0.1); /* Card-like effect */
	padding: 30px;
	border-radius: 10px;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
	transition: transform 0.3s ease, box-shadow 0.3s ease;
	backdrop-filter: blur(10px);
}

.contact-details:hover {
	transform: translateY(-5px);
	box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.contact-details p {
	margin-bottom: 20px;
	line-height: 1.6;
}

.contact-details h3 {
	font-size: 1.8rem;
	margin-bottom: 20px;
	color: #003366;
	font-weight: bold;
}

.contact-details a {
	color: rgba(102, 192, 255, 1);
	text-decoration: none;
	font-weight: bold;
}

.contact-details a:hover {
	text-decoration: underline;
	color: #003366;
}
.contact-icon {
	display: inline-block;
	margin-right: 15px;
	font-size: 1.5rem;
	vertical-align: middle;
}

.contact-map {
	flex: 2;
	background: rgba(255, 255, 255, 0.1);
	border-radius: 10px;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-map:hover {
	transform: translateY(-5px);
	box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
	.contact-content {
		flex-direction: column;
	}

	.contact-map {
		width: 100%;
		margin-top: 20px;
	}
}
