.oferta-section {
	padding: 80px 20px;
	background-color: #f0f8ff;
}

.container {
	max-width: 1200px;
	margin: 0 auto;
	text-align: center;
}

.oferta-heading {
	font-size: 2.5rem;
	color: #003366;
	font-weight: bold;
	margin-bottom: 40px;
}

.oferta-cards {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid */
	gap: 30px; /* Space between cards */
	justify-items: center;
	align-items: center;
}

.oferta-card {
	background-color: white;
	border-radius: 10px;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
	padding: 20px;
	width: 100%;
	height: 100%; /* Make sure all cards take up the same height */
	max-width: 350px; /* Limit the maximum width */
	transition: transform 0.3s ease, box-shadow 0.3s ease, border-radius 0.3s ease;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.oferta-card:hover {
	transform: rotate(3deg); /* Slight rotation on hover */
	box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2); /* Larger shadow */
	border-radius: 15px; /* Slightly rounder corners on hover */
}

.oferta-card h3 {
	font-size: 1.8rem;
	color: #003366;
	margin-bottom: 10px;
}

.oferta-card p {
	font-size: 1.2rem;
	color: #555;
}

.oferta-text {
	margin-top: 40px;
	font-size: 1.2rem;
	color: #003366;
}

.btn-contact {
	margin-top: 30px;
	background-color: rgba(0, 51, 102, 0.85) !important;
	color: white !important;
	padding: 15px 30px;
	font-size: 1.2rem;
	border: none;
	border-radius: 5px;
	text-transform: uppercase;
	transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn-contact:hover {
	background-color: rgba(0, 51, 102, 1) !important;
	transform: translateY(-3px);
}

@media (max-width: 768px) {
	.oferta-cards {
		grid-template-columns: 1fr; /* One column on mobile */
	}

	.oferta-card {
		width: 100%;
		max-width: none;
	}
}
